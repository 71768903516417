import React, { FC } from "react";

import { theme } from "flicket-ui";

import { formatDate } from "~lib";
import { BarChart } from "../charts";
import { addMinutes } from "date-fns";
import { useIsMobile } from "~hooks";

const getDateFormat = (val: number) =>
  `${formatDate(new Date(val), "d LLL p")} - ${formatDate(
    addMinutes(new Date(val), 5),
    "p"
  )}`;

const formatGraphData = (data, timeBased, percentageBased) => {
  if (timeBased) {
    return data?.map(({ start, end, value }) => {
      return {
        date: new Date(start).getTime(),
        scanned: value,
      };
    });
  }

  if (percentageBased) {
    return data?.map(({ name, issued, presented, toCome }) => {
      const scannedPercentage = Math.round((presented / issued) * 100);
      const toScanPercentage = 100 - scannedPercentage;

      return {
        date: name,
        scanned: scannedPercentage,
        expected: toScanPercentage,
        total: issued,
      };
    });
  }

  return data?.map(({ identifier, items }) => {
    const scanned = items?.find((item) => item.name === "Scanned")?.value || 0;
    const expected =
      items?.find((item) => item.name === "Expected")?.value || 0;

    return {
      date: identifier,
      scanned,
      expected,
    };
  });
};

export const ScansChart: FC<{
  data: any;
  timeBased?: boolean;
  percentageBased?: boolean;
}> = ({ data, timeBased = false, percentageBased = false }) => {
  const isMobile = useIsMobile();

  const options = {
    y: {
      tickFormatter: (val) =>
        percentageBased ? `${val || 0}%` : val.toLocaleString(),
      labelFormatter: (val) => val.toLocaleString(),
      ...(percentageBased && { domain: [0, 100] }),
    },
    x: {
      tickFormatter: (val: string | number) =>
        timeBased ? formatDate(new Date(val), "d LLL p") : val,
      labelFormatter: (val: string) => val,
      ...(timeBased && {
        hide: false,
        interval: "preserveStartEnd",
        angle: 0,
        type: "number",
        scale: "time",
        minTickGap: isMobile ? 5 : 50,
        domain: ["dataMin", "dataMax"],
      }),
    },
    tooltip: {
      formatter: (val, name, props) =>
        percentageBased
          ? name === "Scanned"
            ? `${val || 0}%`
            : props?.payload?.total
          : val,
      labelFormatter: (val: string | number) =>
        timeBased ? getDateFormat(val as number) : val,
    },
  };

  const bars = [
    {
      name: "Scanned",
      dataKey: "scanned",
      fill: theme.colors.S300,
    },
    ...(timeBased
      ? []
      : [
          {
            name: percentageBased ? "Expected" : "Issued",
            dataKey: "expected",
            fill: theme.colors.S200,
          },
        ]),
  ].map((item) => (percentageBased ? { ...item, stackId: "a" } : item));

  return (
    <BarChart
      data={formatGraphData(data, timeBased, percentageBased)}
      options={options}
      items={bars}
      hideLegend={timeBased || percentageBased}
    />
  );
};
