import React, { FC } from "react";

import {
  Box,
  Container,
  Flex,
  formatPrice,
  SystemProps,
  Text,
} from "flicket-ui";
import { Icon, Status } from "~components";
import { SummaryOverviewOutput } from "~graphql/sdk";
import { Card, Grid } from ".";
import { useIsMobile } from "~hooks";

interface Props {
  data: SummaryOverviewOutput;
  error: any;
}

export const OverviewSummary: FC<Props & SystemProps> = ({
  data,
  error,
  ...props
}) => {
  const isMobile = useIsMobile();
  const overviewItems = [
    {
      title: "Tickets sold today",
      icon: "my-tickets",
      key: "ticketsSoldToday",
    },
    {
      title: "Tickets sold yesterday",
      icon: "my-tickets",
      key: "ticketsSoldYesterday",
    },
    { title: "Total tickets sold", icon: "my-tickets", key: "ticketsPaid" },

    { title: "Total revenue", icon: "trending_up", key: "totalRevenue" },
    { title: "Today's revenue", icon: "trending_up", key: "todayRevenue" },
    {
      title: "Yesterday's revenue",
      icon: "trending_up",
      key: "yesterdayRevenue",
    },
    { title: "Orders", icon: "orders", key: "orders" },
    {
      title: "Total tickets issued",
      icon: "my-tickets",
      key: "ticketsSold",
    },
    { title: "Cart abandonment", icon: "cart", key: "cartAbandonment" },
    {
      title: "Returning customers",
      icon: "customer",
      key: "returningCustomers",
    },
    { title: "Total comps issued", icon: "my-tickets", key: "ticketsComp" },
    { title: "Memberships issued", icon: "membership", key: "membershipsSold" },
    {
      title: "Memberships renewed",
      icon: "membership",
      key: "membershipsRenewed",
    },
    { title: "Add-ons issued", icon: "add-cart", key: "addonsSold" },
  ];

  return (
    <>
      <Card
        title={"Overall summary"}
        pr={{ _: 0, md: 4 }}
        position="relative"
        {...props}
      >
        <Status loading={!error && !data} error={error}>
          {data?.ticketsSold > 0 ||
          data?.membershipsSold > 0 ||
          data?.addonsSold > 0 ? (
            isMobile ? (
              <Container px={0}>
                {overviewItems.map(({ title, icon, key }) => {
                  if (data?.[key] == "0") {
                    return;
                  } else {
                    return (
                      <Box
                        bg="P100"
                        borderRadius="sm"
                        p={2}
                        flex={1}
                        key={title}
                        mr={{ _: "6/4", md: 0 }}
                        minWidth={{ _: 260, md: 220 }}
                        mb={2}
                      >
                        <Flex alignItems="center" mb={2}>
                          <Flex
                            as="span"
                            borderRadius="full"
                            bg="P300"
                            width={32}
                            height={32}
                            variant="center"
                            mr={1}
                          >
                            <Icon
                              icon={icon as any}
                              color="white"
                              fontSize={4}
                            />
                          </Flex>
                          <Text fontWeight="extraBold" color="N500">
                            {title}
                          </Text>
                        </Flex>
                        <Text fontWeight="extraBold" fontSize={6} color="N600">
                          {key === "totalRevenue" ||
                          key === "todayRevenue" ||
                          key === "yesterdayRevenue"
                            ? formatPrice(data?.[key])
                            : data?.[key]?.toLocaleString()}
                        </Text>
                      </Box>
                    );
                  }
                })}
              </Container>
            ) : (
              <Grid>
                {overviewItems.map(({ title, icon, key }) => {
                  if (data?.[key] == "0") {
                    return;
                  } else {
                    return (
                      <Box
                        bg="P100"
                        borderRadius="sm"
                        p={2}
                        flex={1}
                        key={title}
                        mr={{ _: "6/4", md: 0 }}
                        minWidth={{ _: 260, md: 220 }}
                        mb={2}
                      >
                        <Flex alignItems="center" mb={2}>
                          <Flex
                            as="span"
                            borderRadius="full"
                            bg="P300"
                            width={32}
                            height={32}
                            variant="center"
                            mr={1}
                          >
                            <Icon
                              icon={icon as any}
                              color="white"
                              fontSize={4}
                            />
                          </Flex>
                          <Text fontWeight="extraBold" color="N500">
                            {title}
                          </Text>
                        </Flex>
                        <Text fontWeight="extraBold" fontSize={6} color="N600">
                          {key === "totalRevenue" ||
                          key === "todayRevenue" ||
                          key === "yesterdayRevenue"
                            ? formatPrice(data?.[key])
                            : data?.[key]?.toLocaleString()}
                        </Text>
                      </Box>
                    );
                  }
                })}
              </Grid>
            )
          ) : (
            <Flex height={150} variant="center" width={1}>
              There are no activity to show
            </Flex>
          )}
        </Status>
      </Card>
    </>
  );
};

export default OverviewSummary;
