import { formatPrice } from "flicket-ui";
import { Status } from "~components";
import { Card, OmitTypename } from "~components/reports";
import { FinancialOverviewQuery } from "~graphql/sdk";
import { IconCardGrid, OverviewItem } from "../IconCardGrid";

type FinancialOverviewData = OmitTypename<
  FinancialOverviewQuery["financialOverview"]
>;

interface Props {
  data: FinancialOverviewData;
  error: any;
  isPoint?: boolean;
}

const overviewItems = (
  isPoint: boolean
): OverviewItem<FinancialOverviewData>[] => [
  ...((isPoint
    ? [
        {
          title: "Gross volume",
          icon: "trending_up",
          key: "grossSales",
          description:
            "Volume of transactions after refunds and credits have been deducted.",
        },
      ]
    : [
        {
          title: "Net sales",
          icon: "trending_up",
          key: "grossSales",
          description:
            "Revenue from sales after ticket transfer<br />refunds and general refunds have been deducted.",
        },
      ]) as OverviewItem<FinancialOverviewData>[]),
  ...((isPoint
    ? []
    : [
        {
          title: "Transfer fees",
          icon: "trending_up",
          key: "transferFees",
          description:
            "Total buyer transfer fees plus total seller transfer fees.",
        },
      ]) as OverviewItem<FinancialOverviewData>[]),
  ...((isPoint
    ? []
    : [
        { title: "Discounts", icon: "my-tickets", key: "discounts" },
      ]) as OverviewItem<FinancialOverviewData>[]),
  { title: "Average per order", icon: "credit_card", key: "avgOrderAmount" },
  { title: "Average order size", icon: "bars", key: "avgOrderItemsCount" },
  {
    title: "Ticket protection order value",
    icon: "my-tickets",
    key: "ticketCoverOrderTotal",
  },
  {
    title: "Ticket protection cost",
    icon: "my-tickets",
    key: "ticketCoverCost",
  },
  {
    title: "Ticket protection margin",
    icon: "my-tickets",
    key: "ticketCoverMargin",
  },
];

export const FinancialOverview = ({ data, error, isPoint = false }: Props) => (
  <Card
    title="Financial overview"
    mb={4}
    mt={4}
    pr={{ _: 0, md: 4 }}
    position="relative"
  >
    <Status loading={!error && !data} error={error}>
      <IconCardGrid
        variant="financial"
        items={overviewItems(isPoint)}
        data={data}
        getDataValue={(data, key) =>
          key === "avgOrderItemsCount"
            ? data?.[key].toLocaleString()
            : formatPrice(data?.[key])
        }
        hideZeroValues={true}
      />
    </Status>
  </Card>
);

export default FinancialOverview;
